(function ($) {
    'use strict';

    Drupal.behaviors.fbc_getBugInfo = {
        attach: function (context, settings) {
            $(document).ready(function( $ ) {
                var userLoc = window.location.href;
                var userOs = navigator.platform;
                var userAgent = navigator.userAgent;


                $('#edit-field-bug-page-und-0-value').val(userLoc);
                $('#edit-field-browser-und-0-value').val(userOs);
                $('#edit-field-operating-system-und-0-value').val(userAgent);

            });


        }
    };


}(jQuery));
